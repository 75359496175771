import { FC, memo } from "react";
import {
  Link as RLink,
  useLocation,
} from "react-router-dom";
import { Link, LinkProps } from "@mui/material";

interface Props extends LinkProps {  to: string; activeColor?: string; active?: boolean };

export const BaseLink = memo<Props>(
  ({ activeColor = "secondary.main", active, sx, children, ...rest }) => {
    return (
      <Link
        component={RLink}
        data-active={active || undefined}
        sx={{
          position: "relative",
          fontWeight: "700",
          // divider
          ":after": {
            content: `""`,
            position: "absolute",
            height: "3px",
            width: "100%",
            left: "0",
            bottom: "-7px",
            bgcolor: activeColor,
            opacity: "0",
            pointerEvents: "none",
            transformOrigin: "left",
          },
          // text
          span: {
            display: "inline-block",
          },
          ":after, span": {
            transition: (theme) =>
              theme.transitions.create("transform", {
                duration: theme.transitions.duration.short,
              }),
          },
          ":hover, &[data-active]": {
            ":after": {
              opacity: "1",
              transform: "scaleX(0.3)",
            },
            span: {
              transform: "scale(1.05)",
              color: activeColor,
            },
          },
          ...sx,
        }}
        {...rest}
      >
        <span>{children}</span>
      </Link>
    );
  }
);

const NavLink: FC<Omit<Props, "active">> = (props) => {
  const { pathname, hash } = useLocation();
  return <BaseLink active={pathname + hash === props.to} {...props} />;
};

export default NavLink;
