import { Components, Theme } from "@mui/material";

export default function InputAdornment(
  theme: Theme
): Components["MuiInputAdornment"] {
  return {
    styleOverrides: { root: { color: theme.palette.primary.main } },
    defaultProps: { disablePointerEvents: true },
  };
}
