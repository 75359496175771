import { Components, Theme } from "@mui/material";

export default function Tab(theme: Theme): Components["MuiTab"] {
  return {
    styleOverrides: {
      root: {
        fontSize: "1rem",
        fontWeight: "bold",
        color: theme.palette.primary.main,
      },
    },
  };
}
