import { FC } from "react";
import { Box, Container, Link, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useNav from "../hooks/useNav";
import { BaseLink } from "../components/NavLink";
// images
import logo from "../assets/images/logo.svg";
import appleStore from "../assets/images/apple-app-store-badge.svg";
import googlePlay from "../assets/images/google-play-badge.svg";

const Footer: FC = () => {
  const { t } = useTranslation();
  const { main } = useNav();
  return (
    <Box component="footer" bgcolor="secondary.dark" color="grey.50">
      <Container
        sx={{
          display: "grid",
          gridTemplateColumns: {
            sm: "repeat(2, 1fr)",
            md: "1fr repeat(2, 1.5fr) 1fr",
          },
          gap: 4,
          py: 6,
        }}
      >
        <Box component="img" src={logo} alt="سبق" width="164px" />
        <nav>
          <Box
            component="ul"
            sx={{
              display: "grid",
              gridAutoFlow: { sm: "column" },
              gridTemplateRows: "repeat(3, 1fr)",
              gap: 2,
              listStyleType: "none",
            }}
          >
            {main.map((item, i) => (
              <Box key={i} component="li">
                <BaseLink
                  key={i}
                  {...item}
                  activeColor="white"
                  color="primary.light"
                />
              </Box>
            ))}
          </Box>
        </nav>
        <Box sx={{ img: { height: "46px", maxWidth: "100%" } }}>
          <Typography variant="h4" mb={1.75} color="inherit">
            {t("downloadApp")}
          </Typography>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={0.5}>
            <Link href="#">
              <Box component="img" src={appleStore} alt="Apple Store" />
            </Link>
            <Link href="#">
              <Box component="img" src={googlePlay} alt="Google Play" />
            </Link>
          </Stack>
        </Box>
        <Box>
          <Typography variant="h4" mb={1.75} color="inherit">
            {t("needHelp")}
          </Typography>
          <Link
            href="mailto:info@wtsaudi.com"
            underline="hover"
            color="primary.200"
            fontWeight="700"
          >
            {t("emailUs")}
            <br />
            info@wtsauid.com
          </Link>
        </Box>
      </Container>
      <Box bgcolor="primary.main">
        <Container
          component="p"
          sx={{
            py: (theme) => theme.spacing(1.5),
            fontSize: "small",
            fontWeight: "bold",
          }}
        >
          {t("copyright")}
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
