import { FC, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

/**
 * Requires to be authenticated && active user Account
 */
const RequiresActive: FC<{ children: ReactElement }> = ({ children }) => {
  let { isAuthenticated, user } = useAuth();
  let location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  if (user?.status !== "APPROVED") {
    return (
      <Navigate to="/account-activation" state={{ from: location }} replace />
    );
  }

  return children;
};

export default RequiresActive;
