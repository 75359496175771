import { FC, useEffect } from "react";
import isString from "lodash/isString";
import { DropzoneProps, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
// material
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { AddAPhotoRounded } from "@mui/icons-material";
// utils
import { fData } from "../../utils/formatNumber";
import useAlert from "../../hooks/useAlert";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }: any) => ({
  width: 92,
  height: 92,
  padding: 5,
  borderRadius: "50%",
  backgroundColor: theme.palette.primary["50"]
}));

const DropZoneStyle = styled("div")(({ theme }) => ({
  zIndex: 0,
  width: "100%",
  height: "100%",
  outline: "none",
  display: "flex",
  overflow: "hidden",
  borderRadius: "50%",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  border: `2px solid ${theme.palette.primary.main}`,
  "& > *": { width: "100%", height: "100%" },
  "&:hover": {
    cursor: "pointer",
    "& .placeholder": {
      zIndex: 9
    }
  }
}));

const PlaceholderStyle = styled("div")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: "50%",
  backgroundColor: theme.palette.secondary.dark,
  color: theme.palette.grey["300"],
  transition: theme.transitions.create("opacity", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  "&:hover": { opacity: 0.72 }
}));

// ----------------------------------------------------------------------

interface Props extends DropzoneProps {
  file?: any;
  error?: boolean;
  sx?: any;
};
const UploadAvatar: FC<Props> = ({ error, file, sx, ...other }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections
  } = useDropzone({
    multiple: false,
    ...other
  });

  // report errors
  const alert = useAlert();
  const { t } = useTranslation();
  useEffect(() => {
    fileRejections.forEach(({ file, errors }) => {
      const { name, size } = file;
      const dropzone = {
        accept: other.accept,
        maxSize: other.maxSize && fData(other.maxSize)
      };
      alert({
        severity: "error",
        title: `${name} - ${fData(size)}`,
        children: errors.map((e) => {
          return (
            <Typography key={e.code} component="p" variant="caption">
              - {t(`dropzone.${e.code}`, { dropzone })}
            </Typography>
          );
        })
      });
    });
  }, [fileRejections]); // eslint-disable-line

  return (
    <RootStyle sx={sx}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            borderColor: "error.light",
            bgcolor: "error.lighter"
          })
        }}
      >
        <input {...getInputProps()} />

        {file && (
          <Box
            component="img"
            alt="avatar"
            src={isString(file) ? file : file.preview}
            sx={{ zIndex: 8, objectFit: "cover" }}
          />
        )}

        <PlaceholderStyle
          className="placeholder"
          sx={{
            ...(file && {
              opacity: 0,
              color: "common.white",
              bgcolor: "grey.900",
              "&:hover": { opacity: 0.72 }
            })
          }}
        >
          <AddAPhotoRounded sx={{ width: 24, height: 24, mb: 1 }} />
          <Typography variant="caption">
            {file ? t("update") : t("upload")}
          </Typography>
        </PlaceholderStyle>
      </DropZoneStyle>
    </RootStyle>
  );
};

export default UploadAvatar;
