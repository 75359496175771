/**
 * Builds the Yup Locale
 */
import {TFunction} from "i18next";
import {setLocale} from "yup";

export function buildYupLocale(_: unknown, t: TFunction): void {
  setLocale({
    mixed: {
      required: t("yup.mixed.required"),
    },
    string: {
      email: t("yup.string.email"),
      min: t("yup.string.min"),
    },
  });
}
