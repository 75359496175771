import { TypographyOptions } from "@mui/material/styles/createTypography";
import { Theme } from "@mui/material";

const FONT_PRIMARY = ["Droid Arabic Kufi", "Helvetica ", "sans-serif"].join(
  ","
);

type Typography = (palette: Theme["palette"]) => TypographyOptions;
const typography: Typography = (palette) => ({
  fontFamily: FONT_PRIMARY,
  button: { textTransform: "none" },
  h1: {
    fontWeight: 700,
    fontSize: "2rem",
    color: palette.secondary.main,
  },
  h2: {
    fontWeight: 700,
    fontSize: "1.75rem",
    color: palette.secondary.main,
  },
  h3: {
    fontWeight: 700,
    fontSize: "1.25rem",
    color: palette.secondary.main,
  },
  h4: {
    fontWeight: 700,
    fontSize: "1rem",
    color: palette.secondary.main,
  },
  body2: {
    color: palette.text.secondary,
  },
  subtitle1: {
    fontWeight: 600,
    color: palette.secondary.main,
  },
  subtitle2: {
    fontSize: "small",
    fontWeight: 600,
    color: palette.secondary.main,
  },
});

export default typography;
