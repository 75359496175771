import { Components, Theme } from "@mui/material";

export default function FormHelperText(
  theme: Theme
): Components["MuiFormHelperText"] {
  return {
    styleOverrides: {
      root: { fontSize: ".85rem" },
    },
  };
}
