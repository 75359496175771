import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//
import enLocales from "./en.json";
import arLocales from "./ar.json";
import { buildYupLocale } from "./buildYupLocale";

// ----------------------------------------------------------------------

i18n.use(initReactI18next).init(
  {
    resources: {
      en: { translations: enLocales },
      ar: { translations: arLocales },
    },
    lng: localStorage.getItem("i18nextLng") || "ar",
    fallbackLng: "ar",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
  },
  buildYupLocale
);

export default i18n;
