import { FC, useCallback, useLayoutEffect, useRef } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import IntlTelInput, { Plugin } from "intl-tel-input";

interface Props extends Omit<TextFieldProps, "onChange"> {
  onChange: (value: Partial<PhoneValue>) => void;
};
const PhoneTextField: FC<Props> = ({ onChange, ...rest }) => {
  const rootRef = useRef<HTMLInputElement>(null);
  const itiRef = useRef<Plugin>();

  const handleChange = useCallback(() => {
    onChange({
      number: itiRef.current?.getNumber(),
      isValid: itiRef.current?.isValidNumber(),
    });
  }, [onChange]);

  useLayoutEffect(() => {
    const input = rootRef.current?.querySelector("input");
    if (input) {
      itiRef.current = IntlTelInput(input, {
        formatOnDisplay: false,
        initialCountry: "eg",
        preferredCountries: ["sa", "eg"],
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.js",
      });
      input.addEventListener("countrychange", handleChange);

      // cleanup
      return () => itiRef.current?.destroy();
    }
  }, []); // eslint-disable-line

  return (
    <TextField type="tel" ref={rootRef} onChange={handleChange} {...rest} />
  );
};

export default PhoneTextField;
