import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { addMethod, mixed } from "yup";
import { Navigate, Route, Routes } from "react-router-dom";
import LoadingLogo from "./components/LoadingLogo";
import MainLayout from "./layouts/MainLayout";
import ErrorBoundary from "./components/ErrorBoundary";
import LoginForm from "./components/forms/LoginForm";
import RegisterForm from "./components/forms/RegisterForm";
import RequiresAuth from "./components/RequiresAuth";
import ProfileLayout from "./layouts/ProfileLayout";
import RequiresActive from "./components/RequiresActive";

// custom yup method to convert empty strings to undefined
addMethod(mixed, "optional", function () {
  return this.transform((value) => {
    if (value === undefined || value === "") return undefined;
    return value;
  });
});

const Loadable =
  (Component: LazyExoticComponent<any>): FC =>
  (props) => {
    return (
      <Suspense fallback={<LoadingLogo />}>
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      </Suspense>
    );
  };

// pages
const Home = Loadable(lazy(() => import("./pages/Home")));
const Auth = Loadable(lazy(() => import("./pages/Auth")));
const ResetPasswd = Loadable(lazy(() => import("./pages/ResetPasswd")));
const AccActivation = Loadable(lazy(() => import("./pages/AccActivation")));
const CompEnrolledIn = Loadable(lazy(() => import("./pages/CompEnrolledIn")));
const ProfileEdit = Loadable(lazy(() => import("./pages/ProfileEdit")));
const Page404 = Loadable(lazy(() => import("./pages/Page404")));
const Page500 = Loadable(lazy(() => import("./pages/Page500")));

const App: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path="/auth" element={<Auth />}>
          <Route index element={<Navigate to="/auth/login" replace />} />
          <Route path="login" element={<LoginForm />} />
          <Route path="register" element={<RegisterForm />} />
        </Route>
        <Route
          path="/profile"
          element={
            <RequiresActive>
              <ProfileLayout />
            </RequiresActive>
          }
        >
          <Route index element={<ProfileEdit />} />
          <Route
            path="/profile/competitions-enrolled-in"
            element={<CompEnrolledIn />}
          />
        </Route>
        <Route
          path="/account-activation"
          element={
            <RequiresAuth>
              <AccActivation />
            </RequiresAuth>
          }
        />
        <Route path="/reset-password" element={<ResetPasswd />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="/500" element={<Page500 />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>
    </Routes>
  );
};

export default App;
