import client from "../utils/client";

export const loadBanks = (type: UserType): Promise<Data<Bank[]>> =>
  client.get(`/${type}/load-bank-accounts`);

export const createBank = ({
  type,
  data,
}: {
  type: UserType;
  data: Omit<Bank, "id">;
}): Promise<Data<Bank>> => client.post(`/${type}/create-bank-account`, data);

export const updateBank = ({
  type,
  data,
}: {
  type: UserType;
  data: Bank;
}): Promise<Data<Bank>> =>
  client.put(`/${type}/${data.id}/update-bank-account`, data);
