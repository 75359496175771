import { FC } from "react";
import { Box, BoxProps } from "@mui/material";
import { keyframes } from "@emotion/react";
import logo from "../assets/images/logo.svg";

const fade = keyframes`
  0% {
    opacity: .1
  }
  50% {
    opacity: 1
  }
  100% {
    opacity: .1
  }
`;

const LoadingLogo: FC<BoxProps> = (props) => {
  return (
    <Box
      width="100%"
      height="calc(100vh - var(--header-height))"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Box
        component="img"
        src={logo}
        width="128px"
        sx={{ animation: `${fade} infinite 1.5s` }}
      />
    </Box>
  );
};

export default LoadingLogo;
