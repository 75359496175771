import { Components, Theme } from "@mui/material";
import merge from "lodash/merge";
import Link from "./Link";
import ButtonBase from "./ButtonBase";
import Button from "./Button";
import OutlinedInput from "./OutlinedInput";
import InputAdornment from "./InputAdornment";
import FormHelperText from "./FormHelperText";
import Autocomplete from "./Autocomplete";
import Select from "./Select";
import Tabs from "./Tabs";
import Tab from "./Tab";

export default function componentsOverrides(theme: Theme): Components {
  return merge(theme.components, {
    MuiLink: Link(theme),
    MuiButtonBase: ButtonBase(theme),
    MuiButton: Button(theme),
    MuiOutlinedInput: OutlinedInput(theme),
    MuiInputAdornment: InputAdornment(theme),
    MuiFormHelperText: FormHelperText(theme),
    MuiAutocomplete: Autocomplete(theme),
    MuiSelect: Select(theme),
    MuiTabs: Tabs(theme),
    MuiTab: Tab(theme),
  });
}
