import client from "../utils/client";

export const getCompetitions = (): Promise<Data<Competition[]>> =>
  client.get("/competitions");

export const getEnrolledInComp = (): Promise<Data<Competition[]>> =>
  client.get("/competitor/enrolled-in-competitions");

export const enrollInCompetition = (id: number): Promise<{ message: string }> =>
  client.post(`/competitor/${id}/enroll-in-competition`);
