import { Components, Theme } from "@mui/material";

export default function OutlinedInput(
  theme: Theme
): Components["MuiOutlinedInput"] {
  return {
    styleOverrides: {
      root: {
        borderRadius: "25px",
      },
      multiline: {
        alignItems: "start",
        ".MuiInputAdornment-root": { marginTop: theme.spacing(1.25) },
      },
    },
  };
}
