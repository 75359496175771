import { Components, Theme } from "@mui/material";
import { ExpandMoreRounded } from "@mui/icons-material";

export default function Autocomplete(
  theme: Theme
): Components["MuiAutocomplete"] {
  return {
    styleOverrides: {
      inputRoot: {
        paddingLeft: theme.spacing(1.75),
        paddingRight: theme.spacing(2),
      },
    },
    defaultProps: { disableClearable: true, popupIcon: <ExpandMoreRounded /> },
  };
}
