import { FC } from "react";
import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RLink, useMatch } from "react-router-dom";
import { ExitToApp } from "@mui/icons-material";
import padStart from "lodash/padStart";
import useAuth from "../hooks/useAuth";
import {
  FaEdit,
  SheetPlay,
  SheetStar,
  SheetSuccess,
} from "../components/Icons";

import profileHeroBg from "../assets/images/profile-hero.png";
import { useMutation, useQuery } from "react-query";
import { getStatistics, updatePicture, upload } from "../apis";
import { UploadAvatar } from "../components/upload";

const Divider = styled("div")(({ theme }: any) => ({
  width: "1px",
  height: "18px",
  backgroundColor: theme.palette.primary["200"],
}));

const Count = styled("span")(({ theme }: any) => ({
  marginLeft: theme.spacing(0.5),
  lineHeight: "1",
  fontSize: "2rem",
  fontWeight: "bold",
  color: theme.palette.primary["200"],
  verticalAlign: "middle",
}));

const ProfileHero: FC = () => {
  const { t } = useTranslation();
  const { user, userType, onLogout, onUpdate } = useAuth();
  const match = useMatch("/profile");
  const { data: statistics } = useQuery(
    ["statistics", user?.id],
    getStatistics
  );

  const { mutate } = useMutation(upload, {
    onMutate({ file }) {
      const prevImage = user?.image;
      const url = URL.createObjectURL(file);
      onUpdate({ image: url });
      return { url, prevImage };
    },
    async onSuccess({ path }) {
      const { data } = await updatePicture({
        type: userType,
        data: { image: path },
      });
      onUpdate(data);
    },
    onSettled(data, err, arg, { prevImage, url }: any) {
      // revert-back to prevImage in case of uploading errors
      if (err) onUpdate({ image: prevImage });
      // revoke created object url
      URL.revokeObjectURL(url);
    },
  });

  return (
    <Box
      sx={{
        borderBottom: 4,
        borderColor: "primary.light",
        bgcolor: "secondary.600",
        backgroundImage: `url(${profileHeroBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "grey.100",
      }}
    >
      <Container
        sx={{
          display: { md: "flex" },
          alignItems: "center",
          justifyContent: "space-between",
          py: (theme) => theme.spacing(6),
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          mb={{ xs: 3, md: 0 }}
        >
          <UploadAvatar
            file={user?.image}
            accept=".jpeg,.jpg,.png"
            maxSize={3145728}
            onDrop={(acceptedFiles) => {
              if (acceptedFiles[0]) mutate({ file: acceptedFiles[0] });
            }}
          />
          <Box mt={"6px !important"}>
            <Typography fontWeight="bold">
              {user?.first_name} {user?.second_name}
            </Typography>
            <Typography fontSize="small">{user?.email}</Typography>
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              ml={-0.75}
              mt={-0.5}
            >
              {!match && (
                <Button
                  component={RLink}
                  to="/profile"
                  variant="text"
                  color="inherit"
                  startIcon={
                    <FaEdit
                      sx={{ fontSize: "18px !important", color: "primary.200" }}
                    />
                  }
                >
                  {t("update")}
                </Button>
              )}
              {!match && <Divider />}
              <Button
                variant="text"
                color="inherit"
                startIcon={<ExitToApp sx={{ color: "primary.200" }} />}
                onClick={onLogout}
              >
                {t("logout")}
              </Button>
            </Stack>
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={{ xs: 1, sm: 2 }}
          alignItems="flex-end"
          justifyContent={{ xs: "space-between", md: "flex-start" }}
        >
          <Box>
            <Box mb={1} whiteSpace="nowrap">
              <SheetPlay
                sx={{
                  width: "2.5rem",
                  height: "2.5rem",
                  verticalAlign: "middle",
                  mr: (theme) => theme.spacing(1),
                }}
              />
              <Count>
                {padStart(statistics?.data.enrolled_in_competitions, 2, "0")}
              </Count>
            </Box>
            <Link
              component={RLink}
              to="/profile/competitions-enrolled-in"
              fontSize="small"
              color={"inherit"}
              underline={"hover"}
            >
              {t("compEnrolledIn")}
            </Link>
          </Box>
          <Divider sx={{ display: { xs: "none", sm: "block" } }} />
          <Box>
            <Box mb={1} whiteSpace="nowrap">
              <SheetStar
                sx={{
                  width: "2.5rem",
                  height: "2.5rem",
                  verticalAlign: "middle",
                  mr: (theme) => theme.spacing(1),
                }}
              />
              <Count>
                {padStart(statistics?.data.active_competitions, 2, "0")}
              </Count>
            </Box>
            <Link
              component={RLink}
              to="/profile/competitions-in-review"
              fontSize="small"
              color={"inherit"}
              underline={"hover"}
            >
              {t("compInReview")}
            </Link>
          </Box>
          <Divider sx={{ display: { xs: "none", sm: "block" } }} />
          <Box>
            <Box mb={1} whiteSpace="nowrap">
              <SheetSuccess
                sx={{
                  width: "2.5rem",
                  height: "2.5rem",
                  verticalAlign: "middle",
                  mr: (theme) => theme.spacing(1),
                }}
              />
              <Count>{padStart(statistics?.data.certificates, 2, "0")}</Count>
            </Box>
            <Link
              component={RLink}
              to="/profile/certificates"
              fontSize="small"
              color={"inherit"}
              underline={"hover"}
            >
              {t("certificates")}
            </Link>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default ProfileHero;
