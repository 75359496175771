import { FC, useCallback, useMemo } from "react";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { useTranslation } from "react-i18next";
import client from "../utils/client";
import useAlert from "../hooks/useAlert";
import useAuth from "../hooks/useAuth";
import { AxiosError } from "axios";

const QueryProvider: FC = ({ children }) => {
  const { onLogout } = useAuth();
  const enqueueAlert = useAlert();
  const { t, i18n } = useTranslation();
  client.defaults.headers.common["Accept-Language"] = i18n.language;

  const handleQueryError = useCallback(({ request, response }: AxiosError) => {
    let msg: string = response?.data.message || t("500");
    if (request.status === 401) onLogout();
    else if (request.status === 0) msg = t("notConnected");

    enqueueAlert({ children: msg, severity: "error" });
  }, []); // eslint-disable-line

  const handleMutationError = useCallback((error: AxiosError) => {
    const errors = error.response?.data.errors;
    if (typeof errors === "object") {
      Object.values(errors).forEach((err: any) => {
        const msg = Array.isArray(err) ? err.join(", ") : err;
        enqueueAlert({ children: msg, severity: "error" });
      });
    } else handleQueryError(error);
  }, []); // eslint-disable-line

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          // TODO: remove on production
          refetchOnWindowFocus: false,
        },
      },
      queryCache: new QueryCache({ onError: handleQueryError as any }),
      mutationCache: new MutationCache({ onError: handleMutationError as any }),
    });
  }, []); // eslint-disable-line

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
export default QueryProvider;
