import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL as string;
if (!API_URL) throw new Error("REACT_APP_API_URL env variable is not defined");

const client = axios.create({ baseURL: API_URL });

client.interceptors.response.use((value) => value.data);

export default client;
