import { FC, useMemo } from "react";
// material
import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { arSD, enUS } from "@mui/material/locale";
// hooks
import { useTranslation } from "react-i18next";
//
import "swiper/css";
import "swiper/css/pagination";
import "intl-tel-input/build/css/intlTelInput.min.css";
import typography from "./typography";
import RtlLayout from "./RtlLayout";
import componentsOverrides from "./overrides";
import GlobalThemeStyles from "./GlobalThemeStyles";
import palette from "./palette";
import breakpoints from "./breakpoints";
import shadows from "./shadows";

// ----------------------------------------------------------------------

const ThemeConfig: FC = ({ children }) => {
  const { i18n } = useTranslation();
  const theme = useMemo(() => {
    const theme = createTheme(
      {
        typography,
        breakpoints,
        palette,
        shadows,
        direction: i18n.language === "en" ? "ltr" : "rtl",
      },
      i18n.language === "en" ? enUS : arSD
    );
    theme.components = componentsOverrides(theme);
    return theme;
  }, [i18n.language]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RtlLayout>
          <CssBaseline />
          <GlobalThemeStyles />
          {children}
        </RtlLayout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeConfig;
