import { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const FaEdit: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 28 28" {...props}>
      <path d="M13.875 18.5l1.813-1.813-2.375-2.375-1.813 1.813v0.875h1.5v1.5h0.875zM20.75 7.25c-0.141-0.141-0.375-0.125-0.516 0.016l-5.469 5.469c-0.141 0.141-0.156 0.375-0.016 0.516s0.375 0.125 0.516-0.016l5.469-5.469c0.141-0.141 0.156-0.375 0.016-0.516zM22 16.531v2.969c0 2.484-2.016 4.5-4.5 4.5h-13c-2.484 0-4.5-2.016-4.5-4.5v-13c0-2.484 2.016-4.5 4.5-4.5h13c0.625 0 1.25 0.125 1.828 0.391 0.141 0.063 0.25 0.203 0.281 0.359 0.031 0.172-0.016 0.328-0.141 0.453l-0.766 0.766c-0.141 0.141-0.328 0.187-0.5 0.125-0.234-0.063-0.469-0.094-0.703-0.094h-13c-1.375 0-2.5 1.125-2.5 2.5v13c0 1.375 1.125 2.5 2.5 2.5h13c1.375 0 2.5-1.125 2.5-2.5v-1.969c0-0.125 0.047-0.25 0.141-0.344l1-1c0.156-0.156 0.359-0.187 0.547-0.109s0.313 0.25 0.313 0.453zM20.5 5l4.5 4.5-10.5 10.5h-4.5v-4.5zM27.438 7.063l-1.437 1.437-4.5-4.5 1.437-1.437c0.578-0.578 1.547-0.578 2.125 0l2.375 2.375c0.578 0.578 0.578 1.547 0 2.125z"></path>
    </SvgIcon>
  );
};

export const SheetStar: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M7.529 5.647h7.529v1.882h-7.529z"></path>
      <path d="M7.529 9.412h8.471v1.882h-8.471z"></path>
      <path d="M7.529 13.176h7.529v1.882h-7.529z"></path>
      <path d="M7.529 16.941h7.529v1.882h-7.529z"></path>
      <path d="M7.529 20.706h7.529v1.882h-7.529z"></path>
      <path d="M32 16.941c-0.005-3.825-2.857-6.982-6.55-7.467l-0.038-0.004v-3.272l-6.198-6.198h-15.449v1.882h-3.765v30.118h23.529v-3.765h1.882v-3.823c3.731-0.489 6.583-3.646 6.588-7.47v-0.001zM19.766 3.213l2.431 2.434h-2.432zM21.648 30.118h-19.766v-26.353h1.882v24.469h17.882zM5.647 26.353v-24.469h12.234v5.645h5.647v1.941c-3.736 0.482-6.593 3.643-6.593 7.472s2.857 6.99 6.555 7.468l0.038 0.004v1.941zM24.469 22.588c-3.119 0-5.647-2.528-5.647-5.647s2.528-5.647 5.647-5.647c3.119 0 5.647 2.528 5.647 5.647v0c-0.003 3.117-2.53 5.644-5.647 5.647h-0z"></path>
      <path d="M27.232 16.245c-0.051-0.158-0.195-0.272-0.366-0.277h-0.001l-1.626-0.059-0.558-1.528c-0.058-0.156-0.205-0.264-0.377-0.264s-0.32 0.109-0.376 0.262l-0.001 0.003-0.558 1.528-1.626 0.059c-0.216 0.007-0.388 0.184-0.388 0.401 0 0.128 0.060 0.242 0.154 0.316l0.001 0.001 1.281 1.003-0.447 1.564c-0.010 0.034-0.016 0.072-0.016 0.112 0 0.222 0.18 0.401 0.401 0.401 0.085 0 0.163-0.026 0.228-0.071l-0.001 0.001 1.349-0.909 1.349 0.909c0.063 0.044 0.142 0.070 0.227 0.070 0.222 0 0.401-0.18 0.401-0.401 0-0.040-0.006-0.078-0.017-0.115l0.001 0.003-0.447-1.564 1.281-1.003c0.093-0.073 0.152-0.186 0.152-0.313 0-0.046-0.008-0.090-0.022-0.13l0.001 0.003z"></path>
    </SvgIcon>
  );
};
export const SheetPlay: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M7.529 5.647h7.529v1.882h-7.529z"></path>
      <path d="M7.529 9.412h8.471v1.882h-8.471z"></path>
      <path d="M7.529 13.176h7.529v1.882h-7.529z"></path>
      <path d="M7.529 16.941h7.529v1.882h-7.529z"></path>
      <path d="M7.529 20.706h7.529v1.882h-7.529z"></path>
      <path d="M32 16.941c-0.005-3.825-2.857-6.982-6.55-7.467l-0.038-0.004v-3.272l-6.198-6.198h-15.449v1.882h-3.765v30.118h23.529v-3.765h1.882v-3.823c3.731-0.489 6.583-3.646 6.588-7.47v-0.001zM19.766 3.213l2.431 2.434h-2.432zM21.648 30.118h-19.766v-26.353h1.882v24.469h17.882zM5.647 26.353v-24.469h12.234v5.645h5.647v1.941c-3.736 0.482-6.593 3.643-6.593 7.472s2.857 6.99 6.555 7.468l0.038 0.004v1.941zM24.469 22.588c-3.119 0-5.647-2.528-5.647-5.647s2.528-5.647 5.647-5.647c3.119 0 5.647 2.528 5.647 5.647v0c-0.003 3.117-2.53 5.644-5.647 5.647h-0z"></path>
      <path d="M27.009 16.618l-3.334-2.423c-0.065-0.048-0.147-0.077-0.236-0.077-0.222 0-0.401 0.179-0.402 0.401v4.844c0 0 0 0.001 0 0.001 0 0.155 0.088 0.29 0.217 0.356l0.002 0.001c0.053 0.027 0.116 0.044 0.183 0.044v0c0.089-0 0.171-0.029 0.237-0.079l-0.001 0.001 3.333-2.421c0.102-0.073 0.167-0.191 0.167-0.324s-0.065-0.251-0.166-0.323l-0.001-0.001z"></path>
    </SvgIcon>
  );
};

export const SheetSuccess: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M7.529 5.647h7.529v1.882h-7.529z"></path>
      <path d="M7.529 9.412h8.471v1.882h-8.471z"></path>
      <path d="M7.529 13.176h7.529v1.882h-7.529z"></path>
      <path d="M7.529 16.941h7.529v1.882h-7.529z"></path>
      <path d="M7.529 20.706h7.529v1.882h-7.529z"></path>
      <path d="M32 16.941c-0.005-3.825-2.857-6.982-6.55-7.467l-0.038-0.004v-3.272l-6.198-6.198h-15.449v1.882h-3.765v30.118h23.529v-3.765h1.882v-3.823c3.731-0.489 6.583-3.646 6.588-7.47v-0.001zM19.766 3.213l2.431 2.434h-2.432zM21.648 30.118h-19.766v-26.353h1.882v24.469h17.882zM5.647 26.353v-24.469h12.234v5.645h5.647v1.941c-3.736 0.482-6.593 3.643-6.593 7.472s2.857 6.99 6.555 7.468l0.038 0.004v1.941zM24.469 22.588c-3.119 0-5.647-2.528-5.647-5.647s2.528-5.647 5.647-5.647c3.119 0 5.647 2.528 5.647 5.647v0c-0.003 3.117-2.53 5.644-5.647 5.647h-0z"></path>
      <path d="M23.53 17.493l-1.217-1.216-1.331 1.331 2.548 2.548 4.431-4.431-1.331-1.331z"></path>
    </SvgIcon>
  );
};
