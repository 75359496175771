import jwtDecode, { JwtPayload } from "jwt-decode";
//
import client from "./client";

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string): Boolean => {
  try {
    const decoded = jwtDecode<Required<JwtPayload>>(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  } catch (e) {
    return false;
  }
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete client.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
