// material
import { FC } from "react";
import {useTheme} from "@mui/material/styles";
import {GlobalStyles} from "@mui/material";

// ----------------------------------------------------------------------

const GlobalThemeStyles: FC = () => {
  const theme = useTheme();
  return (
    <GlobalStyles styles={{
      ":root": {
        width: '100%',
        height: '100%',
        direction: "ltr",
        fontSize: "14px",
        // css variables
        "--header-height": "74px",
        "--swiper-theme-color": theme.palette.primary.main,
        "--swiper-pagination-bullet-inactive-color": "white"
      },
      body: {
        width: '100%',
        height: '100%'
      },
      '#root': {
        width: '100%',
        height: '100%',
        paddingTop: "var(--header-height)" // Header placeholder
      },
      ul: {paddingLeft: "0"},
      dl: {margin: 0},
      "dd, dt": {display: "inline-block"},
      "a": {textDecoration: "none"},
      ".iti": {width: "100%", display: "inline-flex"},
      ".iti--allow-dropdown .iti__flag-container": {
        right: "auto",
        left: 0,
      },
      ".iti--allow-dropdown input[type=tel]": {
        textAlign: "left",
        paddingLeft: theme.spacing(5.5),
        paddingRight: theme.spacing(1.75),
      },
      ".iti__selected-flag": {
        paddingLeft: theme.spacing(1.75),
        borderTopLeftRadius: theme.spacing(2.5),
        borderBottomLeftRadius: theme.spacing(2.5),
      },
      ".iti__arrow": {display: "none"},
      ".iti__country-list": {
        textAlign: "left",
        // do not not flip in rtl
        direction: theme.direction === "rtl" ? "rtl" : undefined,
      },
      ".iti-mobile .iti--container": {
        zIndex: 9999,
        left: "10px",
        width: "calc(100% - 20px)",
      },
      "#nprogress": {
        pointerEvents: "none",
        "& .bar": {
          top: 0,
          left: 0,
          height: 2,
          width: "100%",
          position: "fixed",
          zIndex: theme.zIndex.snackbar,
          backgroundColor: theme.palette.secondary.main,
          boxShadow: `0 0 2px ${theme.palette.secondary.main}`,
        },
        "& .peg": {
          right: 0,
          opacity: 1,
          width: 100,
          height: "100%",
          display: "block",
          position: "absolute",
          transform: "rotate(3deg) translate(0px, -4px)",
          boxShadow: `0 0 10px ${theme.palette.secondary.main}, 0 0 5px ${theme.palette.secondary.main}`,
        },
      },

    }}/>
  );
};

export default GlobalThemeStyles;
