import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./locales/i18n";
import {AuthProvider} from "./contexts/JWTContext";
import ScrollToTop from "./components/ScrollToTop";
import ThemeConfig from "./theme";
import NotistackProvider from "./components/NotistackProvider";
import QueryProvider from "./components/QueryProvider";

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ScrollToTop/>
        <ThemeConfig>
          <NotistackProvider>
            <AuthProvider>
              <QueryProvider>
                <App/>
              </QueryProvider>
            </AuthProvider>
          </NotistackProvider>
        </ThemeConfig>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
