import { createContext, FC, useEffect, useReducer } from "react";
import { AxiosError } from "axios";
// utils
import { isValidToken, setSession } from "../utils/jwt";
import { showProfile } from "../apis";
import LoadingLogo from "../components/LoadingLogo";

// ----------------------------------------------------------------------

interface State {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: User | null;
  /** Used to call specific user endpoint. fallback to competitor */
  userType: UserType;
}
const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null as User | null,
  /** Used to call specific user endpoint. fallback to competitor */
  userType: (localStorage.getItem("type") as UserType) || "competitor",
};

enum Types {
  init,
  logout,
  update,
  authenticate,
}

interface Action<T = any> { type: Types; payload: T };

function reducer(state: State, { type, payload }: Action): State {
  switch (type) {
    case Types.init:
      return {
        ...state,
        isInitialized: true,
        user: payload.user,
        isAuthenticated: payload.isAuthenticated,
      };
    case Types.authenticate:
      return {
        ...state,
        isAuthenticated: true,
        user: payload.user,
        userType: payload.user.type.toLowerCase(),
      };
    case Types.update:
      return {
        ...state,
        user: Object.assign({}, state.user, payload.user),
      };
    case Types.logout:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      throw new Error("Invalid action type was provided");
  }
}

interface Context extends State {
  onAuth: (user: User, token: string) => void;
  onInit: (user: User | null) => void;
  onUpdate: (user: Partial<User>) => void;
  onLogout: () => void;
};

const AuthContext = createContext<Context>({
  ...initialState,
  // default value for functions
  onInit: () => {},
  onAuth: () => {},
  onUpdate: () => {},
  onLogout: () => {},
});

const AuthProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // initialize auth
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);
      showProfile(initialState.userType)
        .then(({ data }) => onInit(data))
        .catch(({ request }: AxiosError) => {
          if (request.status === 401) setSession(null);
          onInit(null);
        });
    } else onInit(null);
  }, []);

  function onAuth(user: User, token: string): void {
    setSession(token);
    localStorage.setItem("type", user.type.toLowerCase());
    dispatch({
      type: Types.authenticate,
      payload: { user },
    });
  }

  function onInit(user: User | null): void {
    dispatch({
      type: Types.init,
      payload: { isAuthenticated: !!user, user },
    });
  }

  function onUpdate(user: Partial<User>): void {
    dispatch({
      type: Types.update,
      payload: { user },
    });
  }

  function onLogout(): void {
    setSession(null);
    dispatch({ type: Types.logout, payload: null });
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        onInit,
        onAuth,
        onUpdate,
        onLogout,
      }}
    >
      {state.isInitialized ? children : <LoadingLogo />}
    </AuthContext.Provider>
  );
};

export {AuthContext, AuthProvider};
