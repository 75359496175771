import { FC, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequiresAuth: FC<{ children: ReactElement }> = ({ children }) => {
  let { isAuthenticated, user } = useAuth();
  let location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequiresAuth;
