import { useSnackbar } from "notistack";
import { Alert, AlertProps, AlertTitle } from "@mui/material";
import { useCallback } from "react";

export default function useAlert(): (options: AlertProps) => void {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return useCallback(
    ({title, children, ...rest}) => {
      enqueueSnackbar(null, {
        content: (key) => (
          <Alert {...rest} onClose={() => closeSnackbar(key)}>
            {title && <AlertTitle>{title}</AlertTitle>}
            {children}
          </Alert>
        ),
      });
    },
    [enqueueSnackbar, closeSnackbar]
  );
};
