import { Component, ErrorInfo } from "react";
import { Navigate } from "react-router-dom";

class ErrorBoundary extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) return <Navigate to="/500" />;

    return this.props.children;
  }
}

export default ErrorBoundary;
