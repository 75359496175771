import { FC } from "react";
// stylis
import stylisRTLPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
// emotion
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
// material
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

const cache = createCache({
  key: "css",
  stylisPlugins: [prefixer],
});
const cacheRtl = createCache({
  key: "rtl",
  stylisPlugins: [prefixer, stylisRTLPlugin],
});

const RtlLayout: FC = ({ children }) => {
  const { direction } = useTheme();

  return (
    <CacheProvider value={direction === "rtl" ? cacheRtl : cache}>
      {children}
    </CacheProvider>
  );
};

export default RtlLayout;
