import { PaletteOptions } from "@mui/material";

const palette: PaletteOptions = {
  primary: {
    50: "rgba(237, 227, 203, 0.14)",
    200: "#ebd7a3",
    light: "#e1ca8f",
    main: "#d2ba7c",
    dark: "#9f8a4f",
    contrastText: "#fff",
  },
  secondary: {
    light: "#bf97db",
    main: "#8e69a9",
    "600": "#805aa3",
    dark: "#715188",
    contrastText: "#fff",
  },
  text: { primary: "#606060", secondary: "#88888D" },
};

export default palette;
