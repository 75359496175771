import { Components, Theme } from "@mui/material";

export default function Button(theme: Theme): Components["MuiButton"] {
  return {
    styleOverrides: {
      root: {
        border: "1px solid transparent",
        borderRadius: "25px",
        boxShadow: "none",
        fontWeight: 600,
        ":hover, :focus": {
          boxShadow: "none",
        },
      },
      outlinedPrimary: {
        borderColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
      },
      containedPrimary: {
        borderColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.primary.main,
        },
      },
    },
    defaultProps: { variant: "contained" },
  };
}
