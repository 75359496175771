import { useTranslation } from "react-i18next";
import { useMemo } from "react";

interface NavItem {
  children: string;
  to: string;
}

const useNav = (): { main: NavItem[]; secondary: NavItem[] } => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      main: [
        {
          children: t("home"),
          to: "/",
        },
        {
          children: t("competitions"),
          to: "/#competitions",
        },
        {
          children: t("about"),
          to: "/#about",
        },
        {
          children: t("contactUs"),
          to: "/#contact-us",
        },
        {
          children: t("privacyPolicy"),
          to: "/privacy-policy",
        },
      ],
      secondary: [
        {
          children: t("login"),
          to: "/auth/login",
        },
        {
          children: t("register"),
          to: "/auth/register",
        },
      ],
    }),
    [t]
  );
};

export default useNav;
