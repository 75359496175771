import { FC, useEffect } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link as RLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Lock, Mail, Person, Public } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useMutation, useQuery } from "react-query";
import { getCountries, register as registerApi } from "../../apis";
import PhoneTextField from "../PhoneTextField";
import useAuth from "../../hooks/useAuth";

type FormData = Omit<
  RegisterData,
  "phone" | "first_name" | "second_name" | "third_name" | "fourth_name" | "type"
> & {
  name: string;
  phone: PhoneValue;
};
const RegisterForm: FC = () => {
  const { i18n } = useTranslation();
  const { data, isLoading } = useQuery(
    ["countries", i18n.language],
    getCountries,
    { refetchOnMount: false }
  );
  const { t } = useTranslation();

  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    trigger,
    control,
  } = useForm<FormData>({
    resolver: yupResolver(
      yup.object().shape({
        name: yup
          .string()
          .label(t("name"))
          .matches(/(\S+\s+){3}\S+/, {
            message: t("yup.string.matches.quadName"),
            excludeEmptyString: true,
          })
          .required(),
        email: yup.string().label(t("email")).email().required(),
        phone: yup
          .object()
          .label(t("phone"))
          .test({
            name: "isValidNumber",
            message: t("yup.string.phone"),
            test(value, { createError }) {
              // custom check for phone field
              if (value?.isValid) return true;
              if (!value?.number) {
                return createError({ message: t("yup.mixed.required") });
              }
              return false;
            },
          }),
        password: yup
          .string()
          .label(t("password"))
          .optional()
          .min(8)
          .required(),
        password_confirmation: yup
          .string()
          .label(t("passwordConf"))
          .optional()
          .min(8)
          .required(),
        country_id: yup.number().label(t("country")).required(),
        gender: yup.string().label(t("gender")).required(),
      })
    ),
  });

  useEffect(() => {
    // trigger to translate error messages
    if (isSubmitted) trigger();
  }, [t]); // eslint-disable-line

  const { onAuth } = useAuth();
  const mutate = useMutation(registerApi, {
    onSuccess: (data) => {
      onAuth(data.data, data.token);
    },
  });

  function onSubmit({ phone: { number }, name, ...rest }: FormData) {
    const names = name.split(" ").filter(Boolean);
    mutate.mutate({
      first_name: names[0],
      second_name: names[1],
      third_name: names[2],
      fourth_name: names[3],
      phone: number,
      type: 1,
      ...rest,
    });
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      bgcolor="white"
      py={{ xs: 4, sm: 5 }}
      px={{ xs: 2.5, sm: 5 }}
      borderRadius="16px"
      boxShadow={3}
    >
      <Typography variant="h3" mb={2.5}>
        {t("createAccount")}
      </Typography>
      <Stack spacing={1.5}>
        <TextField
          fullWidth
          placeholder={t("fullName")}
          autoFocus
          // icon
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            ),
          }}
          {...register("name")}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <TextField
          fullWidth
          placeholder={t("email")}
          type="email"
          // icon
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Mail />
              </InputAdornment>
            ),
          }}
          {...register("email")}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, ref, name } }) => (
            <PhoneTextField
              name={name}
              inputRef={ref}
              onChange={onChange}
              fullWidth
              placeholder={t("phone")}
              error={!!errors[name]}
              // @ts-ignore
              helperText={errors.phone?.message}
            />
          )}
        />
        <TextField
          fullWidth
          placeholder={t("password")}
          type="password"
          // icon
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
          }}
          {...register("password")}
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        <TextField
          fullWidth
          placeholder={t("passwordConf")}
          type="password"
          // icon
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
          }}
          {...register("password_confirmation")}
          error={!!errors.password_confirmation}
          helperText={errors.password_confirmation?.message}
        />
        <Controller
          name="country_id"
          control={control}
          render={({ field: { name, ref, onChange } }) => (
            <Autocomplete
              onChange={(e, value) => {
                onChange(value?.id);
              }}
              loading={isLoading}
              options={data?.data || []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              renderInput={({ InputProps, ...restParams }) => (
                <TextField
                  {...restParams}
                  placeholder={t("country")}
                  name={name}
                  inputRef={ref}
                  error={!!errors[name]}
                  helperText={errors[name]?.message}
                  // icon
                  InputProps={{
                    ...InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Public />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {isLoading && (
                          <CircularProgress color="inherit" size={20} />
                        )}
                        {InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
        />
        <FormControl component="fieldset" error={!!errors.gender}>
          <Controller
            name="gender"
            control={control}
            render={({ field: { onChange, value = "", name } }) => (
              <RadioGroup
                row
                aria-label="gender"
                name={name}
                onChange={({ currentTarget }) => {
                  onChange(currentTarget.value);
                }}
                value={value}
                sx={{ paddingX: (theme) => theme.spacing(1.75) }}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={t("male") as string}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label={t("female") as string}
                />
              </RadioGroup>
            )}
          />
          <FormHelperText>{errors.gender?.message}</FormHelperText>
        </FormControl>
      </Stack>
      <Box textAlign="center" mt={2.5}>
        <LoadingButton
          type="submit"
          size="large"
          variant="contained"
          loading={mutate.isLoading}
          sx={{ width: (theme) => `calc(100% - ${theme.spacing(4)})` }}
        >
          {t("register")}
        </LoadingButton>
      </Box>
      <Typography textAlign="center" mt={2}>
        <Typography component="span" color="primary.main">
          {t("haveAccount")}
        </Typography>
        &nbsp;
        <Link color="secondary.main" component={RLink} to="/auth/login">
          {t("login")}
        </Link>
      </Typography>
    </Box>
  );
};

export default RegisterForm;
