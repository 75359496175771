import { FC } from "react";
import { Outlet } from "react-router-dom";
import ProfileHero from "./ProfileHero";

const ProfileLayout: FC = () => {
  return (
    <>
      <ProfileHero />
      <Outlet />
    </>
  );
};

export default ProfileLayout;
