import { Components, Theme } from "@mui/material";

export default function ButtonBase(theme: Theme): Components["MuiButtonBase"] {
  return {
    styleOverrides: {
      root: {
        ":disabled": {
          cursor: "not-allowed",
        },
      },
    },
  };
}
