import { FC, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  styled,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Language, MenuOutlined } from "@mui/icons-material";
import { Link as RLink } from "react-router-dom";
import useNav from "../hooks/useNav";
import useAuth from "../hooks/useAuth";
import NavLink from "../components/NavLink";
import { buildYupLocale } from "../locales/buildYupLocale";
// images
import logo from "../assets/images/logo.svg";
import profileImg from "../assets/images/profile.png";

const RootStyle = styled("header")`
  position: fixed;
  top: 0;
  /* @noflip */
  left: 0;
  z-index: 20;
  /* @noflip */
  width: calc(100% - var(--body-padding-right, 0px));
  height: var(--header-height);
  display: flex;
  align-items: center;
  background-color: white;
`;

const Header: FC = () => {
  const { isAuthenticated, user } = useAuth();
  const { t, i18n } = useTranslation();
  const { main, secondary } = useNav();

  const anchorEl = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  function onToggleLang() {
    const targetLang = i18n.language === "ar" ? "en" : "ar";
    i18n
      .changeLanguage(targetLang)
      .then(() => localStorage.setItem("i18nextLng", targetLang));
    buildYupLocale(null, t);
  }

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        const pr = document.body.style.paddingRight;
        document.documentElement.style.setProperty("--body-padding-right", pr);
      });
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["style"],
    });
    return () => observer.disconnect();
  }, []);

  return (
    <RootStyle sx={{ boxShadow: 1 }}>
      <Container>
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <Link component={RLink} to="/" sx={{ marginRight: 4 }}>
            <Box component="img" src={logo} alt="Sabq" sx={{ width: "72px" }} />
          </Link>
          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            flexGrow={1}
            sx={{ display: { xs: "none", sm: "flex" } }}
          >
            {main.slice(0, -1).map((item, i) => (
              <NavLink key={i} {...item} />
            ))}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            {!isAuthenticated &&
              secondary.map((item, i) => (
                <NavLink key={i} sx={{ fontSize: "small" }} {...item} />
              ))}
            {user && (
              <Stack
                component={RLink}
                to="/profile"
                direction="row"
                spacing={1}
                alignItems="center"
                color="inherit"
              >
                <Box
                  display="flex"
                  width="50px"
                  height="50px"
                  padding="4px"
                  borderRadius="50%"
                  bgcolor="grey.100"
                >
                  <Box
                    component="img"
                    src={user.image || profileImg}
                    alt={user.first_name}
                    width="100%"
                    height="100%"
                    borderRadius="50%"
                    border={1.6}
                    borderColor="secondary.main"
                    sx={{ objectFit: "cover", objectPosition: "center" }}
                  />
                </Box>
                <Box>
                  <Typography variant="h4">
                    {user.first_name} {user.second_name}
                  </Typography>
                  <Typography lineHeight={1.2}>
                    <small>{user.email}</small>
                  </Typography>
                </Box>
              </Stack>
            )}
            <Button
              variant="text"
              startIcon={<Language />}
              onClick={onToggleLang}
            >
              {t("lang")}
            </Button>
          </Stack>
          <IconButton
            id="nav-btn"
            ref={anchorEl}
            onClick={() => setIsOpen(true)}
            aria-label="Menu"
            aria-controls="nav"
            aria-haspopup="true"
            aria-expanded={isOpen}
            color="primary"
            sx={{ display: { md: "none" } }}
          >
            <MenuOutlined />
          </IconButton>
          <Menu
            id="nav"
            anchorEl={anchorEl.current}
            open={isMd && isOpen} // hide on big screens
            onClose={() => setIsOpen(false)}
            MenuListProps={{
              // @ts-ignore
              component: "div",
              "aria-labelledby": "nav-btn",
            }}
          >
            {isSm &&
              main
                .slice(0, -1)
                .map((item, i) => (
                  <MenuItem key={i} component={RLink} {...item} />
                ))}
            <Divider sx={{ display: { xs: "block", sm: "none" } }} />

            {!isAuthenticated &&
              secondary.map((item, i) => (
                <MenuItem key={i} component={RLink} {...item} />
              ))}
            {user && (
              <MenuItem component={RLink} to="/profile">
                {user.first_name} {user.second_name}
              </MenuItem>
            )}
            <Divider />
            <MenuItem
              component="button"
              onClick={onToggleLang}
              sx={{ width: "100%" }}
            >
              {t("lang")}
            </MenuItem>
          </Menu>
        </Stack>
      </Container>
    </RootStyle>
  );
};

export default Header;
