import client from "../utils/client";

interface AuthResponse extends Data<User> {
  token: string;
}

export const login = (data: LoginData): Promise<AuthResponse> =>
  client.post("/login", data);

export const register = (data: RegisterData): Promise<AuthResponse> =>
  client.post("/register", data);

export const showProfile = (type: UserType): Promise<Data<User>> =>
  client.get("/" + type);

export const updateProfile = ({
  type,
  data,
}: {
  type: UserType;
  data: Partial<RegisterData>;
}): Promise<Data<User>> => client.put(`/${type}/update-profile`, data);

export const updatePassword = ({
  type,
  data,
}: {
  type: UserType;
  data: {
    password: string;
    password_confirmation: string;
    old_password: string;
  };
}): Promise<{ message: string }> =>
  client.put(`/${type}/update-password`, data);

export const updatePicture = ({
  type,
  data,
}: {
  type: UserType;
  data: { image: string };
}): Promise<Data<User>> => client.put(`/${type}/update-profile-picture`, data);

export const activate = (data: {
  email: string;
  code: string;
}): Promise<{ message: string }> => client.post("/activate", data);

export const forget = (data: {
  email: string;
}): Promise<{ message: string }> => {
  const formData = new FormData();
  formData.append("email", data.email);
  return client.post("/forget", formData);
};

export const reset = (data: {
  email: string;
  code: string;
  password: string;
  password_confirmation: string;
}): Promise<{ message: string }> => client.post("/reset", data);
