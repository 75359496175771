import { FC } from "react";
import { SnackbarProvider } from "notistack";
import { Slide } from "@mui/material";

// ----------------------------------------------------------------------

const NotistackProvider: FC = ({ children }) => {
  return (
    <SnackbarProvider
      dense
      maxSnack={5}
      TransitionComponent={(props: any) => <Slide {...props} direction="up" />}
      autoHideDuration={5000}
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotistackProvider;
