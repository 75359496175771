import { FC, useEffect } from "react";
import {
  Box,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link as RLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Lock, Mail } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../hooks/useAuth";
import { useMutation } from "react-query";
import { login } from "../../apis";

const LoginForm: FC = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    trigger,
  } = useForm<LoginData>({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().label(t("email")).email().required(),
        password: yup.string().label(t("password")).required(),
      })
    ),
  });

  useEffect(() => {
    // trigger to translate error messages
    if (isSubmitted) trigger();
  }, [t]); // eslint-disable-line

  const { onAuth } = useAuth();
  const { mutate: onLogin, isLoading } = useMutation(login, {
    onSuccess: (data) => onAuth(data.data, data.token),
  });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit((data) => onLogin(data))}
      noValidate
      bgcolor="white"
      py={{ xs: 4, sm: 5 }}
      px={{ xs: 3, sm: 5 }}
      borderRadius="16px"
      boxShadow={3}
    >
      <Typography variant="h3" mb={2.5}>
        {t("login")}
      </Typography>
      <Stack spacing={1.5}>
        <TextField
          fullWidth
          placeholder={t("email")}
          type="email"
          autoFocus
          // icon
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Mail />
              </InputAdornment>
            ),
          }}
          {...register("email")}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <TextField
          fullWidth
          placeholder={t("password")}
          type="password"
          // icon
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
          }}
          {...register("password")}
          error={!!errors.password}
          helperText={errors.password?.message}
        />
      </Stack>
      <Box textAlign="center" mt={2.5}>
        <LoadingButton
          type="submit"
          size="large"
          variant="contained"
          loading={isLoading}
          sx={{ width: (theme) => `calc(100% - ${theme.spacing(4)})` }}
        >
          {t("login")}
        </LoadingButton>
      </Box>
      <Box mt={2.5} textAlign="right" sx={{ a: { color: "secondary.main" } }}>
        <Link component={RLink} to="/reset-password" mb={1}>
          {t("forgetPassword")}
        </Link>
        <Typography>
          <Box component="span" color="primary.main">
            {t("noAccount")}
          </Box>
          &nbsp;
          <Link component={RLink} to="/auth/register">
            {t("createAccount")}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginForm;
